<template>
  <div>
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="#1976d2" size="128" is-full-screen />
    <v-snackbar v-model="showSnackBar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="cropImageDialog" persistent :width="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? '100vw'
        : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
            ? '50vw'
            : '40vw'
      ">
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper :image="currentImage" cropwidth="315" cropheight="420" :key="currentImage"
              @stepper="imageCropper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class="elevation-1">
            Edit Product
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field :rules="Rules" v-model="productcode" label="Product Unique Id"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field :rules="Rules" v-model="productname" @input="checkNameAvailability"
                      label="Product Name"></v-text-field>
                    <span style="color:red;font-size:12px;font-family:poppinsregular">{{ NameMsg }}</span>

                  </v-col>
                  <v-col cols="12" sm="4" md="2">
                    <v-text-field :rules="Rules" v-model="productcost" label="Product Cost"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <v-select dense multiple v-model="category" class="mt-5" label="Category" :items="categoryArray"
                      item-text="name" item-value="_id">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="9">
                    <v-select dense multiple v-model="styl" class="mt-5" label="Styl-Category" :items="stylCategoryArray"
                      item-text="name" item-value="_id">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-autocomplete v-model="user.subcategory" :items="subcategoryArray" class="mt-5" item-text="name"
                      item-value="_id" dense clearable label="Product Subcategory" v-on:input="subSize(user.subcategory)">
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item.name }}
                        </span>
                      </template></v-autocomplete>
                    <!-- <v-select
                      dense
                      class="mt-5"
                      label="Sub-category"
                      v-model="user.subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                      v-on:input="subSize(user.subcategory)"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item.name }}
                        </span>
                      </template>
                    </v-select> -->
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-text-field :rules="Rules" v-model="caption" label="Caption">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select v-model="productTag" color="#FF1313" placeholder="product Tag" :items="productTags"
                      item-text="name" item-value="_id" item-color="#FF1313" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :rules="Rules" v-model="stockquantity" label="Stock Quantity"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="profitmargin"
                      label="Profit Margin"
                    >
                    </v-text-field>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="comissionPercentage" label="Commission Percentage">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="deliveryCharge" label="Delivery Charge">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field :rules="Rules" v-model="description" label="Description">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select multiple dense color="#FF1313" chips label="Regions (Pincodes)" deletable-chips
                      v-model="pincode" :items="pincodeArray" item-text="name" item-value="_id"></v-select>
                  </v-col>
                </v-row>
                <v-flex xs12 md12 lg10 xl7>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="styloopinputlabel">Product Stock</span>
                    </v-flex>
                    <v-flex xs12>
                      <StockAdder v-bind:storage="subSizeArray" v-bind:sizeData="user.size" :key="subSizeArray[0]"
                        @stepper="winStepper" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex text-left pl-2 xs12 md12 lg12>
                  <span style="
                          padding-left: 10px;
                          text-align: left;
                          letter-spacing: 0.54px;
                          font-size: 12px;
                        ">Add Description</span>
                  <v-layout wrap justify-space-between>
                    <v-flex v-for="(tagItem, i) in tagArray" :key="i" pa-2>
                      <v-chip close @click:close="removeTag(i)">{{ tagItem }}
                      </v-chip>
                    </v-flex>
                    <v-flex pa-2>
                      <v-dialog v-model="tagDialog" max-width="500px" transition="dialog-transition">
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" style="cursor: pointer" color="red" outlined>
                            <span style="color: #000000">
                              <v-icon>mdi-plus</v-icon> Add Description
                            </span>
                          </v-chip>
                        </template>
                        <v-card class="pa-2">
                          <v-layout wrap>
                            <v-flex xs12 text-left>
                              <span style="
                                      padding-left: 10px;
                                      text-align: left;
                                      letter-spacing: 0.54px;
                                      font-size: 13px;
                                    ">Add Description</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field v-model="tag" dense outlined placeholder="Add Description" color="#FF1313">
                              </v-text-field>
                            </v-flex>
                          </v-layout>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="tagDialog = false">
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="addTag()">
                              Add
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <!-- </v-layout> -->
                  </v-layout>
                  <!-- </v-chip-group> -->
                </v-flex>
                <v-layout wrap>
                  <v-flex py-2 text-left pl-2 xs12 md12 lg12>
                    <span style="
                          padding-left: 10px;
                          text-align: left;
                          letter-spacing: 0.54px;
                          font-size: 12px;
                        ">Add Material Used</span>
                    <v-layout wrap justify-space-between>
                      <v-flex v-for="(item, i) in materialusedArray" :key="i" pa-2>
                        <v-chip close @click:close="removeMaterial(i)">{{ item }}
                        </v-chip>
                      </v-flex>
                      <v-flex pa-2>
                        <v-dialog v-model="materialDialog" max-width="500px" transition="dialog-transition">
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" style="cursor: pointer" color="red" outlined>
                              <span style="color: #000000">
                                <v-icon>mdi-plus</v-icon> Add Material Used
                              </span>
                            </v-chip>
                          </template>
                          <v-card class="pa-2">
                            <v-layout wrap>
                              <v-flex xs12 text-left>
                                <span style="
                                      padding-left: 10px;
                                      text-align: left;
                                      letter-spacing: 0.54px;
                                      font-size: 13px;
                                    ">Add Material Used</span>
                              </v-flex>
                              <v-flex xs12>
                                <v-text-field v-model="material" dense outlined placeholder="Add Material Used"
                                  color="#FF1313">
                                </v-text-field>
                              </v-flex>
                            </v-layout>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="materialDialog = false">
                                Cancel
                              </v-btn>
                              <v-btn color="blue darken-1" text @click="addMaterial()">
                                Add
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                      <!-- </v-layout> -->
                    </v-layout>
                    <!-- </v-chip-group> -->
                  </v-flex>
                  <v-flex xs12 pl-2>
                    <v-combobox v-model="seotags" :items="items" chips clearable label="SEO Tags" multiple class="pt-3">
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">
                          <strong>{{ item }}</strong>&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-flex>

                  <v-flex xs12 pl-2>
                    <v-combobox v-model="keywords" :items="keywordItems" chips clearable label="Keywords" multiple
                      class="pt-3">
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :input-value="selected">
                          <!-- @click="select"
                        @click:close="remove(item)" -->
                          <strong>{{ item }}</strong>&nbsp;
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close-circle
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 sm8 pl-2>
                    <v-text-field v-model="title" label="Title"> </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 pl-0 pl-sm-8>
                    <v-text-field v-model="fbpixel" label="Facebook pixel">
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex md3 lg3 pl-3 text-left>
                    <v-card pr-4flat tile outlined>
                      <v-layout wrap>
                        <!-- <v-flex md4 lg4>
                          <v-img
                            :src="baseURL + user.photos[0]"
                            height="113px"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"  
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#FF0000"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-flex> -->
                        <v-flex pl-2 md8 lg8>
                          <v-layout wrap>
                            <v-flex pt-2 md6 v-for="(item, i) in user.photos" :key="i" px-2 class="hidden-sm-and-down">
                              <v-badge small overlap avatar color="#FF1313">
                                <template v-slot:badge>
                                  <v-avatar>
                                    <v-icon small @click="removePhotos(i)">mdi-close</v-icon>
                                  </v-avatar>
                                </template>
                                <v-img v-if="item" :src="baseURL + item" height="44px" width="44px" contain>
                                </v-img>
                              </v-badge>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex lg6 md6 v-for="(item, i) in imageArray" :key="i" px-2 class="hidden-sm-and-down">
                              <v-badge small overlap avatar color="#FF1313">
                                <template v-slot:badge>
                                  <v-avatar>
                                    <v-icon small @click="removeImageArray(i)">mdi-close</v-icon>
                                  </v-avatar>
                                </template>
                                <v-img :src="item" height="44px" width="44px">
                                </v-img>
                              </v-badge>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                    <br />
                    <span>
                      <v-btn small :ripple="false" id="pro_pic" class="text-capitalize" color="#EFEFEF " width="80%"
                        @click="$refs.files.click()">
                        <span style="font-size: 12px">Upload Image</span>
                      </v-btn>
                      <input v-show="false" accept="image/*" id="file" ref="files" multiple="multiple" type="file"
                        @change="uploadImages" />
                    </span>
                  </v-flex>
                  <v-flex xs12 v-if="user.brand">
                    <span style="color:red; font-family: opensansbold; font-size: 14px;">Note : Please upload 225(Width) *
                      250 (Height) Pixel Images</span>
                  </v-flex>
                </v-layout>
                <v-row class="justify-center">
                  <v-btn :disabled="!valid" outlined color="red" @click="validate()">
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import StockAdder from "./stockAddrer";
import ImageCropper from "@/components/commonComponents/imageCropper";
import axios from "axios";
export default {
  components: {
    StockAdder,
    ImageCropper,
  },
  props: ["productid", "currentPage"],
  data() {
    return {
      deleteImageArrayDialog: false,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      delFormData: new FormData(),
      selectedFiles: null,
      i: null,
      ServerError: false,
      caption: "",
      comissionPercentage: null,
      deliveryCharge: null,
      description: "",
      file: null,
      image: "",
      tagDialog: false,
      materialDialog: false,
      sizeDialog: false,
      appLoading: false,
      showSnackBar: false,
      productcode: null,
      productname: null,
      productcost: null,
      stockquantity: null,
      styl: [],
      stylCategoryArray: [],
      category: [],
      pincodeArray: [],
      sizeCatArray: [],
      // profitmargin: "",
      tag: "",
      tagArray: [],
      tags: [],
      material: "",
      materialusedArray: [],
      materials: [],
      pincode: [],
      categoryArray: [],
      subcategoryArray: [],
      subcategory: null,
      subSizeArray: [],
      // addSize: "",
      // addStock: "",
      genSize: [],
      genStock: [],
      productStock: {},
      msg: null,
      valid: true,
      user: {
        stockquantity: null,
        instock: false,
        size: [],
      },

      Rules: [(v) => !!v || "Required"],
      imageCount: 0,
      cropImageDialog: false,
      productTags: [],
      productTag: null,
      currentImage: null,
      seotags: null,
      title: null,
      fbpixel: null,
      keywords: [],
      items: ["meta"],
      keywordItems: ["Best Selling", "User Friendly"],
      NameMsg: null,
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
    this.getPincodes();
    this.getSubcategory();
    this.generateSizeStock();
    this.getProductTags();
    this.getStylCategory();
  },
  computed: {
    inStock() {
      if (this.user.stockquantity > 0) {
        return true;
      } else if (this.user.stockquantity == null) return null;
      else return false;
    },
  },
  methods: {
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("photos", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        this.imageCount = this.product.photos.length + this.imageArray.length;
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
    },
    winStepper(window_data) {
      this.productStock = window_data.productStock;
      this.user.size = this.productStock;
      this.user.stockquantity = 0;
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].stock.length; j++) {
          this.user.stockquantity =
            this.user.stockquantity + this.productStock[i].stock[j].stock;
        }
      }
    },
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editUser();
      }
    },
    subSize(id) {
      for (var i = 0; i < this.subcategoryArray.length; i++) {
        if (id == this.subcategoryArray[i]._id) {
          this.subSizeArray = this.subcategoryArray[i].size;
        }
      }
    },
    // addSizes() {
    //   this.sizeCatArray.push({ size: this.addSize, stock: this.addStock });
    //   this.stockquantity = this.stockquantity + this.addStock;
    //   this.addSize = "";
    //   this.addStock = "";
    // },
    removeSize(i) {
      this.stockquantity = this.stockquantity - this.sizeCatArray[i].stock;
      this.sizeCatArray.splice(i, 1);
    },
    addTag() {
      this.tagArray.push(this.tag);
      this.tag = "";
    },
    // remove(i){
    //   this.keywordItems.splice(i, 1);
    // },
    removeTag(i) {
      this.tagArray.splice(i, 1);
    },
    addMaterial() {
      this.materialusedArray.push(this.material);
      this.material = "";
    },
    removeMaterial(i) {
      this.materialusedArray.splice(i, 1);
    },
    getProductTags() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/producttag/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.productTags = response.data.data;
              this.productTags.push({
                name: "No Tag",
                _id: "noTag",
              });
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getStylCategory() {
      this.appLoading = true;
      axios({
        url: "/styl/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.stylCategoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubcategory() {
      this.appLoading = true;
      axios({
        url: "/subcategory/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.subcategoryArray = response.data.data;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/product/info",
        method: "GET",
        params: {
          id: this.productid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.productname = this.user.productname;
          this.productcode = this.user.productcode;
          this.productcost = this.user.productcost;
          // this.profitmargin = this.user.profitmargin;
          this.productTag = this.user.productTag;
          this.pincode = this.user.pincodeGroup;
          this.stockquantity = this.user.stockquantity;
          this.caption = this.user.caption;
          this.comissionPercentage = this.user.comissionPercentage;
          this.deliveryCharge = this.user.deliverycharge;
          this.description = this.user.description;
          this.materialused = this.user.materialused;
          this.seotags = this.user.seotags;
          this.keywords = this.user.keywords;
          this.fbpixel = this.user.fbpixel;
          this.title = this.user.title;
          this.subSize(this.user.subcategory._id);

          for (var i = 0; i < this.user.category.length; i++) {
            this.category.push(this.user.category[i]._id);
          }
          this.subcategory = this.user.subcategory._id;

          for (var k = 0; k < this.user.tags.length; k++) {
            this.tagArray.push(this.user.tags[k]);
          }
          for (var t = 0; t < this.user.materialused.length; t++) {
            this.materialusedArray.push(this.user.materialused[t]);
          }
          for (var l = 0; l < this.user.size.length; l++) {
            this.sizeCatArray.push(this.user.size[l]);
          }
          for (var m = 0; m < this.user.styl.length; m++) {
            this.styl.push(this.user.styl[m]._id);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    generateSizeStock() {
      for (var i = 5; i <= 60; i++) {
        this.genSize.push(i);
      }
      for (var j = 0; j <= 1000; j++) {
        this.genStock.push(j);
      }
    },
    getPincodes() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/pincode/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.pincodeArray = response.data.data;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removePhotos(i) {
      var Data = {};
      this.user.photos.splice(i, 1);
      Data["productid"] = this.productid;
      Data["sellerid"] = this.user.seller._id;
      Data["position"] = i + 1;
      axios({
        method: "POST",
        url: "/Product/RemoveImagesingle",
        data: Data,
        headers: {
          // "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
            this.$emit("stepper", {
              editProduct: true,
              getList: true,
            });
            // this.$router.go(-1);
          } else {
            this.msg = "Can't Delete Image";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      console.log(this.formData);
      this.imageArray.splice(i, 1);
      this.deleteImageArrayDialog = false;
    },
    uploadImages(event) {
      if (this.imageCount < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
      // if (this.imageArray.length < 4) {
      //   this.selectedFiles = event.target.files[0];
      //   this.Images.append("image", this.selectedFiles);
      //   this.formData.append("photos", this.selectedFiles);
      //   console.log("files", this.Images);
      //   var img;
      //   img = new Image();
      //   img.src = window.URL.createObjectURL(event.target.files[0]);
      //   img.onload = () => {
      //     const urls = URL.createObjectURL(this.selectedFiles);
      //     if (this.user.photos.length + this.imageArray.length < 4) {
      //       this.imageArray.push(urls);
      //     } else {
      //       this.msg = "Maximum image limit exceeded!";
      //       this.showSnackBar = true;
      //     }
      //     if (this.imageArray) {
      //       this.image = this.imageArray[0];
      //     }
      //   };
      // } else {
      //   this.msg = "Maximum image limit exceeded!";
      //   this.showSnackBar = true;
      //   return;
      // }
    },
    uploadEditedImages() {
      console.log(this.product);
      this.formData.append("productid", this.productid);
      this.formData.append("sellerid", this.user.seller._id);
      axios({
        method: "POST",
        url: "/Product/UploadImageMultiple",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showSnackBar = true;
            this.msg = "Uploaded Successfully";
            this.$emit("stepper", {
              getList: true,
            });
            // this.$router.go(-1);
            if (this.user.status == "Pending")
              // this.$router.push("/pendingProducts");
              this.$router.go(-1);
            else if (this.user.status == "Active") this.$router.go(-1);
            // this.$router.push("/Products/productView");
          } else {
            this.msg = "Can't Upload Images";
            this.showSnackBar = true;
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var user = {};
      user["productcode"] = this.productcode;
      user["productname"] = this.productname;
      user["productcost"] = this.productcost;
      // user["profitmargin"] = this.profitmargin;
      user["subcategory"] = this.user.subcategory;
      user["productTag"] = this.productTag;
      user["category"] = this.category;
      user["styl"] = this.styl;
      user["pincodeGroup"] = this.pincode;
      user["tags"] = this.tagArray;
      user["materialused"] = this.materialusedArray;
      user["size"] = this.productStock;
      user["caption"] = this.caption;
      user["comissionPercentage"] = this.comissionPercentage;
      user["deliverycharge"] = this.deliveryCharge;
      user["description"] = this.description;
      user["id"] = this.productid;
      // this.user.stockquantity = (this.user.stockquantity);
      user["stockquantity"] = Number(this.stockquantity);
      user["seotags"] = this.seotags;
      user["keywords"] = this.keywords;
      user["fbpixel"] = this.fbpixel;
      user["title"] = this.title;

      if (this.user.stockquantity > 0) this.user.instock = true;
      axios({
        method: "POST",
        url: "/Product/Edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showSnackBar = true;
            if (this.selectedFiles) this.uploadEditedImages();
            // else
            // this.$router.go(-1);
            else if (this.user.status == "Pending") this.$router.go(-1);
            // this.$router.push("/pendingProducts");
            else if (this.user.status == "Active") this.$router.go(-1);
            // this.$router.push("/Products/productView");
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showSnackBar = true;

            // this.$emit("stepper", {
            //   getList: true,
            // });
            // this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    checkNameAvailability() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/name/available",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          name: this.productname
        }
      })
        .then((response) => {
          if (response.data.status == true) {

            // this.NameMsg = response.data.msg;
            // this.appLoading = false;

          }
          else {
            this.NameMsg = response.data.msg;
            // this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>